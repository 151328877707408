.support-title {
	position:relative;
	width:auto;height:260px;
	&.bg-white {
		background:#000;
		/* PJTWAUS-1 20200312 modify */  
		.txt-wrap {
			background-color: rgba(0, 0, 0, 0.01);
		}
		/* // PJTWAUS-1 20200312 modify */  
	}
	@include screen(custom, min, $min-md) {
		margin:0 -15px;
	}
	@include screen(custom, max, $max-sm) {
		margin:0 9px;
	}
	.img-wrap {
		width:100%;height:100%;
		overflow:hidden;
	}
	@include screen(custom, max, $max-sm) {
		margin:0 -15px;
		height:220px;
	}
	.img-area {
		position:relative;width:100%;height:100%;
		max-width:(1284px+30px);
		margin:0 auto;
		z-index:0;
		img.pc {
			position:absolute;
			left:50%;top:0;
			margin-left:-800px;
			display:inline;
			@include screen(custom, max, $max-sm) {
				display:none;
			}
		}
		img.mobile {
			position:absolute;
			left:50%;top:0;
			margin-left:(-768px/2);
			display:none;
			@include screen(custom, max, $max-sm) {
				display:inline;
			}
		}
	}
	.txt-area {
		position:absolute;
		left:0;top:0;width:100%;height:100%;
		@include screen(custom, max, $max-sm) {
			padding:0 15px;
			height:auto;
			top:50%;
			transform: translateY(-50%);
		}
		.txt-wrap {
			max-width:(1284px+30px);
			margin:0 auto 50px;
			@include screen(custom, max, $max-sm) {
				margin:0 9px 0;
			}
		}
		.title {
			text-align:center;
			font-size:48px;line-height:56px;color:$color-white;
			@include font-family($font-semibold);
			padding:78px 0 0 0;
			@include screen(custom, max, $max-sm) {
				//max-width:292px;
				max-width:300px;
				margin:0 auto;
				font-size:32px;line-height:40px;
				padding:0;
			}
		}
		.desc {
			text-align:center;
			font-size:18px;line-height:24px;color:$color-white;
			@include font-family($font-regular);
			padding:3px 0 11px 0;
			@include screen(custom, max, $max-sm) {
				max-width:270px;
				margin:0 auto;
				padding:6px 0 11px 0;
				font-size:16px;line-height:22px;
			}
		}
	}
}
@include screen(print) {
	.support-title {display:none;}
}
