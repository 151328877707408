@charset 'UTF-8';

@import './setting/variables';

// container
.support-wrap {
	@import './support/support-title';
	@import './support/no-content';
	@import './support/email-common';
	@import './support/email-result';
	@import './support/banner-slendy';
	@import './modal/form-area';
	@import './modal/find-my-model';
}
