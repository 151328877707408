.no-content {
	p {margin:0;}
	// display:none;
	padding: 150px 0;
	text-align:center;
	@include screen(custom, max, $max-sm){
		padding:100px 0;
	};
	.icon {
		display:inline-block;
		svg {fill:$line-gray;}
	}
	.guide-title, .guide-subtext {
		&:empty {
			display:none;
			width:0;
			height:0;
			position: relative;
			z-index:-100;
		}
	}
	.guide-title {
		display:block;
		@include font-family($font-semibold);
		font-size:32px;
		line-height: 40px;
		color:$color-black;
		margin: 20px 0 5px;
		@include screen(custom, max, $max-sm){
			font-size:24px;
			margin: 18px 0 2px;
		}
	}
	.guide-subtext {
		font-size:16px;
		line-height:24px;
		@include screen(custom, max, $max-sm){
			display:inline-block;
			max-width:210px;
			font-size:14px;
			line-height:20px;
		}
	}
	.linker {
		color:$color-carmine;
		text-decoration:underline;
	}
	.red {
		color:$color-carmine;
	}
}

// search topic page style
.topic-search-area {
	.no-content {
		padding: 70px 0;
	}
}
