.modal {
	.modal-form-area {
		padding: 20px;
		@include screen(custom, max, $max-sm) {
			padding: 12px;
		}
		background-color: $bg-whitesmoke;
		.field-block {
			margin-top: 12px;

			&:first-child {
				margin-top: 0;
			}
			.required-text {
				display: none;
				color: $color-carmine;
				font-size: 14px;
				line-height: 24px;
				margin:2px 0 0 10px;
			}

			&.required {
				.required-text {
					display: block;
				}
			}
		}
		// description text
		.desc {
			@include font-family($font-semibold);
			font-size: 16px;
			line-height: 24px;
			color: $color-nightrider;
		}
	}
	.migration-contents {
		box-sizing: border-box;
		border: 1px solid $line-gray;
		color: $color-dimgray;
		font-size: 16px;
		line-height: 24px;
		padding: 19px;
		margin-top: 10px;

		.find-img {
			margin-bottom: 25px;
			img {
				max-width: 100%;
				display: block;
				margin: 0 auto;
			}
		}
		
		display: none;
		&.active {
			display: block;
		}
	}
}