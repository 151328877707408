.modal {
	// default message
	.find-model-default {
		display:none;
		padding:14px 0 7px 0;
		text-align:center;
		.notice {
			display:inline-block;
		}
		&.active {display:block;}
	}
}