.support-banner-type-slendy {
	position:relative;
	overflow:hidden;
	margin-top:20px;
	height: 126px;
	@include screen(custom, max, $max-sm) {
		height:207px;
	}
	.banner-image {
		position: absolute;;
		top:0; left:50%;
		transform:translate(-50%,0);
	}
	.text-block {
		position:absolute;
		top:50%; left:0;
		transform:translate(0,-50%);
		width:100%;
		padding:0 30px;
		color:$color-white;
		@extend %clearfix;
		@include screen(custom, max, $max-sm) {
			top:50%; left:0;
			width:100%;
		}

		// responsive width
		@include screen(custom, min, $min-md) {
			.text-area, .btn-area {
				display:inline-block;
				vertical-align:middle;
			}
			.text-area {
				width:80%; max-width:calc(100% - 205px);
				padding-left: 26%;
			}
			.btn-area {
				width:20%; min-width:205px;
				text-align:right;
			}
		}
		@include screen(custom, min, $min-lg) {
			.text-area {
				padding-left: 37%
			}
		}
		
		.text-area {
			.text {
				font-size:24px;
				line-height:24px;
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {
					font-size:20px;
					line-height:26px;
					width:50%;
				}
			}
			.sub-text {
				margin-top:4px;
				font-size:16px;
				line-height:24px;
				@include font-family($font-regular);
				@include screen(custom, max, $max-sm) {
					display:none;
				}
			}
		}
		.btn-area {
			@include screen(custom, max, $max-sm) {
				margin-top:13px;
			}
			.btn {
				background:$bg-white !important;
				color:$color-black !important;
			}
		}
		// color variation
		&.black {
			color:$color-black;
		}
	}
}