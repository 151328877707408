.step-in-form {
	// reset n normalize
	em {font-style:normal;}
	p, ul {margin:0;}
	input {
		width:100%;
	}
	margin:24px 0 50px;

	// inheritance
	@include font-family($font-regular);
	@include placeholder($color-gray);
	@include screen(custom, max, $max-sm) {
		margin: 34px -5px 20px;
	}
	// step element :: [step-] level
	.form-inner {
		max-width:1284px;
		margin:auto;
	}
	.unclear-required {
		.require {
			display: none;
		}
		&.setted {
			.require {
				display: inherit;
			}
		}
	}
	.require {
		margin:0 4px;
	}
	.start-guide {
		@extend %clearfix;
		font-size:16px;
		line-height:24px;
		.guide-text {
			color:$color-dimgray;
			float:left;
			width:calc(100% - 115px);
			word-break: break-word;
			> span, > a {
				color:$color-carmine;
				@include screen(custom, max, $max-sm) {
					display:inline-block;
				}
			}
		}

		.require-area {
			float:right;
			width:115px;
		}
		&.no-require{
			.guide-text{
				width:100%;
			}
			.require-area {
				display:none;
			}
		}
		@include screen(custom, max, $max-sm) {
			font-size:14px;
			text-align: right;
			.require-area {
				float:none;
				display:block;
				width:100%;
				font-size:14px;
				line-height:20px;
				margin-top:17px;
			}
			.guide-text {
				float:none;
				display:block;
				text-align:left;
				font-size:16px;
				line-height:24px;
				width:100%;

			}
		}
	}
	.field-block {
		margin-top: 12px;
		&:first-child, &.first-of-type{
			margin-top: 0;
		}
		@include screen(custom, max, $max-sm) {
			margin-top: 25px;
		}
	}
}
.email-common {
	max-width:1314px;
	margin:50px auto;
	padding:0 15px;
	@include screen(custom, max, $max-sm) {
		margin:40px 0;
		padding:0 9px;
	}
	/* [LGEUS-349] 20200413 add */
	#ceo-email-form-select {
		.field-block {
			text-align: right;
			white-space: nowrap;
		}
		.btn {
			& + .btn {
				margin-left: 10px;
			}
			&.btn-outline-secondary {
				background-color: $bg-white !important;
			}
		}
		@include screen(custom, max, ($max-lg + 80) ) {
			.btn {
				display: block;
				width: 100%;
				& + .btn {
					margin-top: 10px;
					margin-left: 0;
				}
			}
		}
	}
	.email-ceo-msg {
		margin-top: -40px;
		.title-ceo {
			padding: 13px 0 34px;
			border-bottom: 2px solid #333;
			font-size: 48px;
			font-family: $font-semibold;
			text-align: center;
		}
		.content-ceo-msg {
			padding-top: 50px;
			border-bottom: 1px solid #e4e4e4;
			.para-ceo {
				font-size: 18px;
				line-height: 20px;
				color: #6b6b6b
			}
			.para-ceo-sign {
				font-size: 18px;
				line-height: 20px;
				text-align: right;
				font-weight: bold;
				/* LGEUS-349 20200421 Edit  */
				img {
					width: 182px;
					height: auto;
				}
				/* // LGEUS-349 20200421 Edit  */
				.name-text {
					padding-top: 5px;
					display: block;
				}
			}
		}
		.btn-group-center {
			padding: 20px 0 50px;
			text-align: center;
			.btn {
				min-width: 275px;
				 & + .btn {
					 margin-left: 10px;
				 }
			}
		}
		@include screen(custom, max, $max-sm) {
			margin-top: -40px;
			padding: 0;
			.title-ceo {
				padding: 20px 0 30px;
				font-size: 32px;
				line-height: 36px;
			}
			.content-ceo-msg {
				padding-top: 30px;
				.para-ceo {
					font-size: 16px;
					line-height: 24px;
				}
				.para-ceo-sign {
					padding-top: 30px;
					font-size: 16px;
					line-height: 24px;
				}
			}
			.btn-group-center {
				.btn {
					display: block;
					min-width: 100%;
					 & + .btn {
						 margin-left: 0;
						 margin-top: 10px;
					 }
				}
			}
		}
		& + .step-in-form {
			@include screen(custom, min, $min-md) {
				margin-bottom: 100px;
			}
		}
	}
	/* // [LGEUS-349] 20200413 add */

	.step-in-form {
		@include screen(custom, max, $max-sm) {
			margin: 34px 0 20px;
		}
	}
	.login-area{
		padding:23px 20px 23px 20px;
		text-align:center;
		border:1px solid $line-gray;
		margin-top:20px;
		p{
			margin:0 0 17px 0;
			color:$color-nightrider;
			font-size:16px;
			line-height:24px;
			@include screen(custom, max, $max-md) {
				margin-bottom:7px;
			}
		}
	}
	.step-in-form{
		@include screen(custom, max, $max-md) {
			margin-top:0;
		}
		.start-guide{
			margin-bottom:7px;
		}
	}
	.email-cluster {
		padding:29px 217px 29px 217px;
		@include screen(custom, max, $max-lg) {
			padding-left:117px;
			padding-right:117px;
		}
		@include screen(custom, max, $max-md) {
			padding-left:57px;
			padding-right:57px;
		}
		@include screen(custom, max, $max-sm) {
			padding:6px 19px 21px 19px;
		}
		margin-bottom:20px;
		border:1px solid $line-gray;
		min-height:100px;
		background:$bg-whitesmoke;

		.inner{
			@extend %clearfix;
			.head-title {
				@include font-family($font-semibold);
				min-height:47px;
				font-size:24px;
				color:$color-black;
				border-bottom:1px solid $line-gray;
				@include screen(custom, max, $max-sm) {
					font-size:14px;
				}
			}
			.left-column, 
			.right-column{
				width:calc(50% - 12px);
				height:100%;
				@include screen(custom, max, $max-sm) {
					width:100%;
					margin-right:0;
				}
			}
			.right-column {
				@include screen(custom, min, $min-md) {
					position: absolute;
					top:0; right:0;
				}
			}
		}
		&.type-select{
			dl {
				@extend %clearfix;
				margin:0;
				dt{
					float:left;
					/* LGEUS-605 20200610 modify */
					width:calc(30%-12px);
					/* //LGEUS-605 20200610 modify */
					margin-right:24px;
					margin-bottom:0;
					padding-top:7px;
					font-size:20px;
					color:$color-black;
					@include screen(custom, max, $max-sm) {
						float:none;
						width:100%;
						margin-bottom:4px;
						font-size:14px;
						@include font-family($font-semibold);
					}
					/* PJTWAUS-1 20200207 modify */
					label {
						margin-bottom:0;
						font-weight:400;
					}
					/* //PJTWAUS-1 20200207 modify */
				}
				dd{
					float:left;
					/* LGEUS-605 20200610 modify */
					width:calc(70%-12px);
					/* //LGEUS-605 20200610 modify */
					margin-bottom:0;
					@include screen(custom, max, $max-sm) {
						float:none;
						width:100%;
					}
				}
			}
			.dl_box {
				@extend %clearfix;
				margin:0;
				.dt_box{
					float:left;
					width:calc(50%-12px);
					margin-right:24px;
					margin-bottom:0;
					padding-top:7px;
					font-size:20px;
					color:$color-black;
					@include screen(custom, max, $max-sm) {
						float:none;
						width:100%;
						margin-bottom:4px;
						font-size:14px;
						@include font-family($font-semibold);
					}
					label {
						margin-bottom:0;
						font-weight:400;
					}
				}
				.dd_box{
					float:left;
					width:calc(50%-12px);
					margin-bottom:0;
					@include screen(custom, max, $max-sm) {
						float:none;
						width:100%;
					}
				}
			}
		}
		&.your-product{
			padding-top:19px;
			@include screen(custom, max, $max-sm) {
				padding-top:13px;
			}
			.head-title {
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {
					font-size:16px;
					min-height:38px;
				}
			}
			.find-model {
				.box-title {
					display:inline-block;
					margin:20px 0 7px 0;
					font-size:16px;
					color:$color-nightrider;
					@include font-family($font-semibold);
					@include screen(custom, max, $max-sm) {
						font-size:14px;
						margin:17px 0 4px 0;
					}
				}
				.write-type{
					.box-title {
						margin-top:0;
						@include screen(custom, max, $max-sm) {
							margin-top:14px;
						}
					}
					.link-text{
						margin:4px 0 1px 12px;
					}
				}
				.submit{
					width:48px;
				}
				.field-block{
					font-size:16px;
					line-height:24px;
					@include screen(custom, max, $max-sm) {
						margin-top:14px;
						&:first-child, &.first-of-type{
							margin-top: 0;
						}
					}
				}
			}
			.column-box{
				position:relative;
				height:100%;
				margin-top:20px;
				@include screen(custom, max, $max-sm) {
					margin-top: 0;
				}
				.model-image-box {
					text-align:center;
					background:$bg-white;
					@include screen(custom, max, $max-sm) {
						height:331px;
						margin-top: 20px;
					}
					.boxing {
						position: absolute;
						top:50%; left:50%;
						transform: translate(-50%, -50%);
						width:100%;
					}
					.model-view-image{
						position: relative;
						height:100%;
						background-color:$bg-white;
						padding-top:30px;
						@include screen(custom, max, $max-sm) {
							padding-top:24px;
						}
						.image-box {
							text-align:center;
							display:block;
							img {
								max-width:160px;
								max-height:160px;
								@include screen(custom, max, $max-sm) {
									max-width:180px;
									max-height:180px;
								}
							}
						}
						.product-info {
							margin-top:6px;
							@include screen(custom, max, $max-sm) {
								margin-top:21px;
							}
							.link-text {
								display:block;
								font-size:20px;
							}
							.text-info {
								display:block;
								font-size:14px;
								line-height:20px;
								color:$color-nightrider !important;
								text-align:center;
								margin-top:9px;
								padding:0 10px;
								@include screen(custom, max, $max-sm) {
									margin-top:5px;
								}
								span:first-child{
									display:block;
								}
							}
						}
						.delete {
							position:absolute;
							right:0;top:0;
							display: block;
							width:37px; height:37px;
							padding:0;
							border: none;
							background: transparent;
							.icon {
								position:absolute;
								left:9px; top:9px;
								@extend %circleDeletebtn;
							}
						}
					}
					.no-model-select{
						position: relative;
						height:100%;
						background-color:$bg-white;
						.img-box {
							position: relative;
							top:8px;
							@include screen(custom, max, $max-sm){
								text-align:center;
								background:$bg-white;
							}
							img {
								width:110px;
								height:77px;
							}
						}
						.text-info {
							margin-top:32px;
							@include screen(custom, max, $max-sm){
								margin-top:24px;
							}
							// .title {
							// 	display:block;
							// 	margin-top:26px;
							// 	font-size:24px;
							// 	color:$color-nightrider;
							// }
							.text {
								display:block;
								font-size:16px;
								color:$color-nightrider;
								@include screen(custom, max, $max-sm){
									font-size:14px;
									line-height: 20px;
									max-width:320px;
									margin:auto;
								}
							}
						}
					}
					// replace <br> by line change
					.no-model-select .text > span:first-child {
						display:block;
					}
					.unavailable-model {
						.model-number {
							display:block;
							color:$color-carmine;
						}
					}
				}
			}
			.select-product{
				.my-Products {
					position:relative;
					max-width:(1284px+30px);
					margin:0 auto;
					.title {
						padding:16px 0 0 0;
						position:relative;
						@include screen(custom, max, $max-sm) {
							padding:7px 0 0 0;
						}
						.link-text {
							position:absolute;
							right:-4px;
							top:39px;
							@include screen(custom, max, $max-sm) {
								position:static;
								margin-bottom:15px;
							}
						}
						strong {
							@include font-family($font-semibold);
							font-size:16px;
							line-height:20px;
							color:$color-nightrider;
							@include screen(custom, max, $max-sm) {
								font-size:14px;
							}
						}
						.desc {
							@include font-family($font-regular);
							font-size:16px;
							padding:0 0 7px 0;
							color: $color-dimgray;
							@include screen(custom, max, $max-sm) {
								font-size:14px;line-height:18px;
								padding:0 0 5px 0;
							}
						}
					}
					.product-type {
						position:relative;
						width:100%;
						padding:20px 50px;
						// max-height:305px;
						overflow:hidden;
						@include screen(custom, max, $max-sm) {
							padding: 20px 0;
							margin-bottom:0;
							// max-height:203px;
						}
						.items {
							@extend %clearfix;
							width:100%;
							a {
								display:block;
							}
							.slick-list {
								width:auto;
								height:100%;
								overflow:hidden;
							}
							.slick-arrow {
								padding:0;
								&.slick-prev,
								&.slick-next {
									position:absolute;
									top:95px;
									width:27px;
									height:46px;
									transform: translate(0,-50%);
								}
								&.slick-prev {
									left:20px;
								}
								&.slick-next {
									right:20px;
								}
								&.slick-disabled {
									opacity:0.3;
								}
								@include screen(custom, max, (1284+30)) {
									&.slick-prev {
										left:20px;
									}
									&.slick-next {
										right:20px;
									}
								}
							}
							opacity:0;
							&.slick-initialized {
								opacity:1;
							}
						}
						.item {
							position:relative;
							float:left;
							padding:10px 0 0;
							outline: none;
							@include screen(custom, max, $max-sm) {
								padding: 0;
							}
							@include screen(custom, min, $min-lg) {
								&:nth-child(4n-3) {&:before {display:none;}}
							}
							@include screen(md) {
								&:nth-child(3n-2) {&:before {display:none;}}
							}
							@include screen(custom, max, $max-sm) {
								&:nth-child(2n-1) {&:before {display:none;}}
							}
							.visual,
							.model-name {
								width: 100%;
								text-align: center;
								margin:0 auto;
							}
							.visual {
								// background:rgba(165,0,52,0.2);
								// img {width:100%;height:auto;}
								width: auto;
								a {
									display:block;
								}
								img {
									max-width: 100%;
									width:120px;
								}
								@include screen(custom, max, $max-sm) {
									padding: 0 15px;
									box-sizing: border-box;
									img {
										max-width: 100px;
										width: auto !important;
									}
								}
							}
							.model-name {
								// min-height:290px;
								// background:rgba(165,0,52,0.2);
								margin-top:20px;
								padding: 0 5px;
								text-align:center;
								@include screen(custom, max, $max-sm) {
									margin-top:15px;
								}
								.text {
									//height: 59px;
									color: $color-black;
										font-size: 16px;
										line-height: 20px;
										display:block;
										@include screen(custom, max, $max-sm) {
											font-size: 14px;
											line-height:14px;
										}
									@include screen(custom, max, $max-sm) {
										height: auto;
										// margin-bottom: 13px;
									}
								}
							}
						}
						// request repair my products
						.no-content {
							// padding: 60px 0;
							padding:20px 0 0 ;
							.guide-title {
								display: none;
							}
							.guide-subtext {
								display: block;
								margin: 14px auto;
								color: $color-nightrider;
							}
							// @include screen(custom, max, $max-xs) {
							// 	padding: 25px 0;
							// }
						}

						// slick carousel stylesheet
						.slick-slider {
							button {
								appearance: none;
								border: none;
								background-color: transparent;
								text-indent: -9999em;
								// line-height: 0;
								// outline: none;
							}
							.slick-track {
								margin-left: auto;
								margin-right: auto;
							}
							.slick-arrow {
								line-height:0;
								svg {
									display: block;
								}
								color: $color-gray;
								&:hover,
								&:focus {
									color: $color-nightrider;
								}
							}
							.slick-dots {
								font-size: 0;
								text-align: center;
								margin-top:23px;
								height:12px;
								@include screen(custom, max, $max-sm) {
									margin-top: 16px;
									margin-bottom:0;
									height:10px;
								}
								li {
									display: inline-block;
									width: 12px;
									height: 12px;
									position: relative;
									border-radius: 50%;
									margin-left: 10px;
									line-height:normal;
									&:first-child {
										margin-left: 0;
									}
									@include screen(custom, max, $max-sm) {
										width:10px;
										height:10px;
										margin-left: 8px;
									}
									a, button {
										background: $color-white; /* PJTWAUS-1 : 20200311 modify */
										display:block;
										width:100%;
										height:100%;
										margin:0;
										padding:0;
										border:$color-dimgray 1px solid; /* PJTWAUS-1 : 20200311 modify */
										border-radius:50%;
										&:focus {
											outline:1px dotted;outline:5px auto -webkit-focus-ring-color;
										}
									}
									&.slick-active {
										a, button {
											background: $color-carmine;
											border: 0; /* PJTWAUS-1 : 20200311 add */
										}
									}
									&:only-child {
										display:none;
									}
								}
							}
						}
					}
				}
			}
		}
		&.about-you{
			padding-top:19px;
			@include screen(custom, max, $max-sm) {
				padding-top:13px;
				padding-bottom:5px;
			}
			.head-title {
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {
					font-size:16px;
					min-height:38px;
				}
			}
			.write-list-wrap {
				padding-top:24px;
				@include screen(custom, max, $max-sm) {
					padding-top:14px;
				}
			}
			.list > .write-type{
				float:left;
				width:calc(50% - 12px);
				margin-bottom:24px;
				&:first-child{
					margin-right:24px;
				}
				.box-title {
					display:inline-block;
					margin:0;
					font-size:16px;
					color:$color-nightrider;
					@include font-family($font-semibold);
					@include screen(custom, max, $max-sm) {
						font-size:14px;
					}
				}
				.field-block{
					margin-top:7px;
					@include screen(custom, max, $max-sm) {
						margin-top:4px;
					}
					.noedit {
						background-color: #e0e0e0;
						color: #444;
					}
				}
				@include screen(custom, max, $max-sm) {
					float:none;
					width:100%;
					margin-bottom:14px;
				}
			}
		}
		&.about-your-inquiry{
			padding-top:19px;
			@include screen(custom, max, $max-sm) {
				padding-top:13px;
				padding-bottom:13px;
			}
			.head-title {
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {
					font-size:16px;
					min-height:38px;
				}
			}
			.write-list-wrap {
				padding-top:24px;
				@include screen(custom, max, $max-sm) {
					padding-top:14px;
				}
			}
			.list > .write-type{
				float:left;
				width:calc(50% - 12px);
				margin-bottom:24px;
				&:first-child{
					margin-right:24px;
				}
				.box-title {
					display:inline-block;
					margin:0;
					font-size:16px;
					color:$color-nightrider;
					@include font-family($font-semibold);
					@include screen(custom, max, $max-sm) {
						font-size:14px;
					}
				}
				.field-block{
					margin-top:7px;
					@include screen(custom, max, $max-sm) {
						margin-top:4px;
					}
				}
				&.type-full {
					width: 100%;
				}
				&.intext-area {
					width: 100%;
					margin:0;
					textarea {
						width:100%;
						height:141px;
						padding:5px 10px;
						font-size:16px;
						line-height:22px;
						border:1px solid $line-input;
						background:$bg-white;
						color:$color-black;
						@include font-family($font-regular);
						@include placeholder($color-gray);
						&:focus {
							border:2px solid $line-nero;
							background-color: #f8fbff;
						}
						@include screen(custom, max, $max-sm) {
							font-size:14px;
							line-height:18px;
						}
					}
					.left-text {
						float: right;
						font-size:14px;
						margin-top: -3px;
						line-height: 20px;
						color:$color-dimgray;
						@include screen(custom, max, $max-sm) {
							margin-top: -1px;
						}
					}
				}
				@include screen(custom, max, $max-sm) {
					float:none;
					width:100%;
					margin-bottom:14px;
				}
			}
		}
		&.captcha-area{
			@include screen(custom, max, $max-sm) {
				padding-top:13px;
			}
			.box-title {
				font-size:16px;
				color:$color-nightrider;
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {
					font-size:14px;
					line-height:16px;
				}
			}
		}
		&.privacy-policy{
			padding-top:19px;
			background-color:$bg-white;
			@include screen(custom, max, $max-sm) {
				padding-top:13px;
			}
			.write-type{
				font-size:16px;
				color:$color-nightrider;
				.title {
					// font-size:14px;
					font-size:16px;
				}
				@include font-family($font-semibold);
				.check-area{
					margin-top:10px;
					.checkbox-box {
						.checkbox-btn{
							top:0;
						}
					}
					.text{
						font-size:16px;
						color:$color-dimgray;
						@include screen(custom, max, $max-sm) {
							font-size:14px;
						}
						// .link-text{
						// 	font-size:14px;
						// }
					}
				}
			}
		}
	}
	.field-btn-area{
		text-align:center;
		button{
			width:249px;
			@include screen(custom, max, $max-sm) {width:100%;}
		}
	}
	
	// Captcha
	.captcha-wrap {
		margin-top:14px;
		@extend %clearfix;
		@include screen(custom, max, $max-sm) {
			margin-top:7px;
		}
		.captcha-box,.input-num {
			display:inline-block;
			width:calc(50% - 12px);
			min-height:75px;
			@include screen(custom, max, $max-sm) {
				width:100%;
				min-height:auto;
			}
		}
		.input-num {
			float:right;
			margin-top:0;
			@include screen(custom, max, $max-sm) {float:left;margin-top:0;}
		}
		.captcha-box{
			margin-right:24px;
			@include screen(custom, max, $max-sm) {margin-right:0;}
			.captcha{
				display:inline-block;
				width:calc(100% - 42px);
				height:74px;
				background:#dcdcdc;
				text-align:center;
				img {display:inherit;width:100%;height:100%;}
			}
			.input-box{
				display:inline-block;
				margin-left:6px;
				span {
					&.btn-op{
						display:block;
						width:32px;
						height:32px;
						margin-bottom:10px;
						border:1px solid $line-gray;
						&:nth-child(2) {
							margin-bottom:0;
						}
					} 
					.LBD_ReloadLink,
					.LBD_SoundLink {
						display:inline-block;
						width:100%;
						height:100%;
						text-indent:-9999px;
						background-position:50% 50%;
						background-repeat:no-repeat;
					}
					.LBD_ReloadLink{
						background-image:url(/lg5-common/images/common/icons/reload-gray.svg);
					}
					.LBD_SoundLink{
						background-image:url(/lg5-common/images/common/icons/voice-reading-gray.svg);
					}
				}
			}
			@include screen(custom, max, $max-md) {
				margin-bottom:20px;
			}
		}
	}
}
.ccpa {
	max-width: 1314px !important;
    margin: 0 auto;
    padding: 0 15px 70px 15px;

	.guide-text{
		width:100% !important;
		display:block;
		//LGEUS-18
		margin-bottom:45px;
		margin-bottom:4.5rem;
		&.guide-text:first-child{
			margin-bottom:25px;
			margin-bottom:2.5rem;
		}		
		//LGEUS-18
		
		@include screen(custom, max, $max-sm) {
			font-size:14px;
			margin-bottom:2.4rem;
		}

		a {
			text-decoration: underline;
		}
	}
	.inner{
		background-color: #f8f8f8;
	    border: 1px solid #e4e4e4;
	    padding: 0 30px;
	    margin-bottom:25px;

		.field-wrap{
			position: relative;
		    max-width: 984px;
		    margin: auto;
		    .signin-status{
		    	padding-top: 38px;
	    		padding-bottom: 49px;
	    		
	    		@include screen(custom, max, $max-sm) {
	    			padding-top: 26px;
	    			padding-bottom: 10px;
	    		}
	    		.field-block{
	    			margin-top:0;
	    		}
		    }
			.field-container{
				color:#6B6B6B;
				label{
					margin-bottom: .8rem;
					margin-top: 2.65rem;
					color:#333;
					font-size:16px;
					font-family:'LG Smart SemiBold', 'LG Smart_Global Regular', 'LG Smart_Global';

					@include screen(custom, max, $max-sm) {
						margin-top:1.2rem;
						margin-bottom:0.5rem;
					}
				}
			}				
			.sign-in-title{
				min-height: 47px;
			    border-bottom: 1px solid #e4e4e4;
			    color:#6B6B6B;
			    font-size:16px;
			    
			    @include screen(custom, max, $max-sm) {font-size:14px;}
			}
			.sign-in-text{
				margin-top:6px;
				margin-left:10px;
			}
			.error-msg{
				padding-top:10px;
			}
			.email-cluster {
				border:none;
				padding:0;

				.inner {
					width:100%;
					border:none;
				}
			}
		}
	}
	
	.require-area {
		margin-bottom:15px;
	}
	.field-btn-area {
		text-align:center;
		margin-top:20px;
		button {
			width:249px;
			
			@include screen(custom, max, $max-sm) {width:100%;}
		}
	}
}
.ccpa-form {
	max-width: 1314px !important;
    margin: 0 auto;
    padding: 0 15px 70px 15px;

    @include screen(custom, max, $max-md) {
		padding-left:0px;
		padding-right:0px;
	}

    .inquiry-to-buy-wrap {
    	padding:15px 0;
    }

	.start-guide{
		font-size: 16px;
	    line-height: 24px;
	    margin-bottom: 13px;
	}
	.inquiry-box-wrap {
	    margin-bottom: 20px;
	    min-height: 100px;
	    border: 1px solid #e4e4e4;
	    padding: 19px 217px 5px 217px;
	    background: #f8f8f8;
	    display:inline-block;
	    width:100%;

	    @include screen(custom, max, $max-lg) {
			padding-left:117px;
			padding-right:117px;
		}
		@include screen(custom, max, $max-md) {
			padding-left:57px;
			padding-right:57px;
		}
		@include screen(custom, max, $max-sm) {
			padding:6px 19px 14px;
		}

	    .write-list-wrap{
	    	padding-top:14px;
	    }
	    .list{
	    	.write-type{
	    		float: left;
			    width: calc(50% - 12px);
			    margin-bottom: 24px;

			    @include screen(custom, max, $max-sm) {width:100%;}
	    	}
	    	.write-type:first-child{
	    		margin-right: 24px;
	    	}
	    	.full-width{
	    		width:100% !important;
			}
		}		
	    .field-block {			
			margin-top:7px;
	    }
	    .intext-area{
	    	width: 100% !important;
			margin:0 0 18px 0;
			textarea {
				width:100%;
				height:141px;
				padding:5px 10px;
				font-size:16px;
				line-height:22px;
				border:1px solid $line-input;
				background:$bg-white;
				color:$color-black;
				@include font-family($font-regular);
				@include placeholder($color-gray);
				&:focus {
					border:2px solid $line-nero;
					background-color: #f8fbff;
				}
				@include screen(custom, max, $max-sm) {
					line-height:18px;
				}
			}
			.left-text {
				float: right;
				font-size:14px;
				margin-top: -3px;
				line-height: 20px;
				color:$color-dimgray;
				@include screen(custom, max, $max-sm) {
					margin-top: -1px;
				}
			}
	    }
	    label{
	    	display: inline-block;
		    margin: 0;
		    font-size: 16px;
		    line-height: 20px;
		    color: #333;
		    font-family: "LG Smart SemiBold",sans-serif;
			font-weight: 400;
			&.mobile {
				display: none;
			}
	    }
	}
	/* [LGEUS-298] 20200506 add */
	.request-wrap {
		@extend %clearfix;
		.intext-area{
			display:none;
		}
		.request-area{
			display:none;
			width:100% !important;
			p.note{
				color:$color-dimgray;
				text-align:left;
				margin-bottom:30px;
				@include screen(custom, max, $max-md) {
					margin-bottom:15px;
				}
			}			
		}			
		.request-belt{
			@extend %clearfix;
			margin-top:29px;
			li{
				float: left;
				width: calc(33.33% - 16px);
				margin-right: 24px;
				&:last-child{
					margin-right:0;
				}
				a{
					width:100%;
					color:$color-nightrider;
					background-color:$color-white;
					letter-spacing:-0.05em;
					@include screen(custom, max, $max-md) {
						font-size:12px;
						letter-spacing:0em;
						line-height:1.4em;
						padding:3px 3px;
					}
				}
				@include screen(custom, max, $max-md) {
					float:none;
					width:100%;
					margin-right:0;
					margin-top:10px;
					&:first-child{
						margin-top:0;
					}
					a:after{
						margin-left:10px;
					}
				}
			}
			@include screen(custom, max, $max-md) {
				margin-top: 10px;
			}
		}
		.in-step-request{
			margin-top: 32px;
			text-align: center;
			@include screen(custom, max, $max-md) {
				margin-top: 20px;
				button{
					width:100%;
				}
			}
		}
		
	}		
	.inquiry-box-wrap.necessaryInfo_area{
		display:none;
		.choice_smartTv{
			display: none;
		}
		.list{
			@extend %clearfix;
		}
		.link-text{
			margin-top:5px;
		}
		.replace-file-input label{
			line-height:40px;
			color:$color-white;
			font-size:14px;
			@include screen(custom, max, $max-sm) {
				color:transparent;
			}
		}
		.box-tille-qna{
			position: relative;
			.btn-tooltip-question span{
				background:$bg-dimgray;
			}
			.tooltip-area{
				min-width:400px;
				@include screen(custom, max, $max-sm) {
					min-width:285px;
				}
			}
		}
		.note{				
			display: block;
			color:$color-dimgray;
			margin-top:5px;			
		}
		.infoBottom{
			@extend %clearfix;
			margin:20px 0;
			padding-top:8px;
			border-top: 1px solid #a4a4a4;
			@include screen(custom, max, $max-sm) {
				margin:5px 0;
				padding-top:0px;
			}
		}		
		.choice_phone{
			display:none;
			.ccpa_bg{
				text-align:center;
				background:$bg-whitesmoke;
				padding:15px 18px 25px;
				@include screen(custom, max, $max-md) {
					padding:15px 10px 20px;
				}
				.note{
					margin:0 auto 10px;
					color:$color-nightrider;
				}
				img{
					width:100%;
					max-width:350px;
					border:1px solid #cfcfcf;
				}
			}
		}
		@include screen(custom, max, $max-md) {
			.list>.write-type:last-child{margin-bottom:24px !important;}
		}
	}		
	.inquiry-box-wrap.optOut_otn{
		display:none;
		.note{
			color:$color-dimgray;
			margin-bottom:15px;
		}
		.link-text{
			margin-top:5px;
		}		
	}
	.request_pop{
		.ccpa_bg{
			background:$bg-whitesmoke;
		};
		#tvDeviceInfo{
			.note{
				margin-bottom:30px;
			};
			.ccpa_bg{
				text-align:center;				
				padding:15px 18px 40px;
				img{
					width:100%;
					max-width:500px;
				}
				@include screen(custom, max, $max-md) {
					padding:15px 10px 20px;
				}
			}
		};
		#macAddr_info {
			.ccpa_bg{
				padding:17px 18px 20px;
				@include screen(custom, max, $max-md) {
					padding:15px 10px 20px;
				}
				.macInfoTop{
					padding-bottom:10px;
					border-bottom:1px solid #ccc;
					h3{
						font-size:16px;
						color:$color-carmine;
					}
				}
				.macInfoCenter{
					text-align:center;
					margin:20px 0 10px;
					.stepTit{
						color:$color-nightrider;
						margin-bottom:5px;
						font-weight:bold;
						text-align:left;
					}
					ol{
						display:inline-block;
						margin-left:-18px;
						li{
							max-width:190px;
							display:inline-block;
							margin-left:18px;
							vertical-align:top;
							margin-bottom:10px;
							img{
								width:100%;
								border:1px solid #ccc
							}
							p.stepNote{
								text-align:left;
								text-indent:-43px;
								padding-left:45px;
								margin:5px 0 0;
								strong{
									color:$color-nightrider;
									font-weight:bold;
								}
							}
						}
						@include screen(custom, max, $max-md) {
							margin-left:0;
							li{
								max-width:350px;
								display:block;
								margin-left:0;
								margin-bottom:20px;
								p.stepNote{
									font-size:12px;
									text-indent:-37px;
									padding-left:38px;
								}
							}
						}
					}
				}
				.macInfoBottom {
					.note{
						color:$color-carmine;
						margin-top:30px;
					}	
					dl{
						margin-bottom:14px;
							dt{
								font-weight:bold;
								color:$color-nightrider;
								
							}
							dd{
								color:$color-dimgray;
								line-height:1.3em;
							}
					}
				}
			}
		};

		#modalPhpop1,#modalPhpop2,#modalPhpop3{
			.ccpa_bg{
				text-align:center;
				padding:15px 18px 25px;
				@include screen(custom, max, $max-md) {
					padding:15px 10px 20px;
				}
				.note{
					display:block;
					margin:0 auto 10px;
				}
				img{
					width:100%;
					max-width:350px;
					border:1px solid #cfcfcf;
				}
			}
		}
		#modalPhpop4{
			.ccpa_bg{
				text-align:center;
				padding:15px 18px 25px;
				@include screen(custom, max, $max-md) {
					padding:15px 10px 20px;
				}
				.note{
					display:block;
					margin:0 auto 10px;
				}
				ol{
					@extend %clearfix;
					li{
						width:33.33%;
						float: left;
						padding:0 3px;
						img{
							width:100%
						}
						@include screen(custom, max, $max-md) {
							width: 100%;
							float:none;
							padding:3px 0;
						}	
					}	
				}										
			}
		}
	}
	/* // [LGEUS-298] 20200506 add */
	.inquiry-box-wrap:after {
		clear:both;
		content:"";
	}
	.privacy-policy {
		margin-bottom:10px !important;
	}
	h2.head-title{
		font-family: "LG Smart SemiBold",sans-serif;
	    font-weight: 400;
	    min-height: 45px;
	    font-size: 24px;
	    color: #000;
	    border-bottom: 1px solid #e4e4e4;

	    .note{
	    	display: block;
		    font-weight: 400;
		    font-style: normal;
		    font-size: 15px;
		    color: #6B6B6B;
		    line-height: 24px;
		    font-family: "LG Smart Regular",sans-serif;
		    margin-bottom: 10px;
	    }
	}
	h3.box-title {
		color: #333;
	    font-family: "LG Smart SemiBold",sans-serif;
	    font-weight: 400;
	    font-size:1rem;
	}
	.step-in-form {
		margin-bottom:0;
		padding:0;
	}
	.field-btn-area{
		text-align:center;
		button {
			width: 249px;
		}
	}
	legend {
		width:1px;
		height:1px;
		display:block;
		text-indent:-9999px;
		overflow:hidden;
	}
}

.ccpa-authentication {
	.guide-text{
		width:100% !important;
		display:block;
		margin-bottom:50px;
		margin-bottom:5rem;
		
		@include screen(custom, max, $max-sm) {
			font-size:14px;
			margin-bottom:2.4rem;
		}

		a {
			text-decoration: underline;
		}
	}
	.require-area {
		margin-bottom:15px;
	}
	.inner{
		background-color: #f8f8f8;
	    border: 1px solid #e4e4e4;
	    padding: 0 30px;
	    margin-bottom:25px;

		.field-wrap{
			position: relative;
		    max-width: 984px;
		    margin: auto;
		    .signin-status{
		    	padding-top: 38px;
	    		padding-bottom: 28px;
	    		
	    		@include screen(custom, max, $max-sm) {
	    			padding-top: 26px;
	    			padding-bottom: 10px;
	    		}
	    		.field-block{
	    			margin-top:0;
	    		}
		    }
			.field-container{
				color:#6B6B6B;
				label{
					margin-bottom: .8rem;
					margin-top: 2.65rem;
					color:#333;
					font-size:16px;
					font-family:'LG Smart SemiBold', 'LG Smart_Global Regular', 'LG Smart_Global';

					@include screen(custom, max, $max-sm) {
						margin-top:1.5rem;
						margin-bottom:0.5rem;
						padding-top:0;
					}
				}
				.field-block{
					input{
						width: calc(100% - 150px);
						margin-right:10px;
						float:left;

						@include screen(custom, max, $max-sm) {
							width:100%;
						}
					}
					button {
						width:140px;

						@include screen(custom, max, $max-sm) {
							width:100%;
						}
					}
					.wrap_btn{
						@include screen(custom, max, $max-sm) {
							margin-top:1.5rem;
							padding-top:1.5rem;
							border-top: 1px solid #e4e4e4;
							display: inline-block;
							width: 100%;
						}
					}
				}
			}				
			.sign-in-title{
				min-height: 46px;
			    border-bottom: 1px solid #e4e4e4;
			    color:#6B6B6B;
			    font-size:16px;
			    
			    @include screen(custom, max, $max-sm) {font-size:14px;}
			}
			.sign-in-text{
				margin-top: 2.65rem;
				border-top: 1px solid #e4e4e4;
				color:#686868;
				font-size:14px;
				padding-top: 1.4rem;

				@include screen(custom, max, $max-sm) {
					margin-top:1.2rem;
					margin-bottom:0.5rem;
					border-top: none;
					padding-top:0;
				}

				button{
					width: auto !important;
				    margin-left: 10px;
				    font-family: 'LG Smart SemiBold','LG Smart_Global Regular','LG Smart_Global';
				    border-top:none !important;
				}
			}
			.error-msg{
				padding-top:10px;
			}
			.email-cluster {
				border:none;
				padding:0;

				.inner {
					width:100%;
					border:none;
				}
			}
		}
	}
}

.ccpa-complete {
	max-width: 1314px !important;
    margin: auto;

	.complete-wrap {
		margin: 0 0 70px;
    	padding: 0 15px;
		@include font-family($font-regular);
		@include screen(custom, max, $max-sm) {
			margin: 10px -5px 40px;
		}
		
	}
	.ccpa-number {
		font-size:20px;
		color:#333;
		line-height:24px;
		margin-top: 1.7rem;
		padding:0 20px;

		@include screen(custom, max, $max-sm) {
			margin-top:1rem !important;
		}

		strong {
			font-weight:600;
			color:#A50034;
			font-family:'LG Smart SemiBold', 'LG Smart Regular', 'LG Smart';
			display:inline-block;
		}
	}
	.btn-box{
		display:none;

		@include screen(custom, max, $max-sm) {
			display:block;
			margin-top: 1.7rem !important;
		}
	}
	.completion-box {
		text-align: center;
	    padding: 63px 0 70px;
	    border: 1px solid #e4e4e4;
	    .completion-title{
	    	display: block;
		    font-size: 32px;
		    margin: 17px 0 2px;
		    font-family: "LG Smart SemiBold",sans-serif;
		    font-weight: 400;
		    @include screen(custom, max, $max-sm) {
				font-size: 24px;
    			margin-top: 24px;
			}
	    }
	    .completion-text {
			display:block;
			margin-top:.7rem !important;
			padding:0 20px;
			font-size: 16px;
	    	color: #6b6b6b;
	    	@include screen(custom, max, $max-sm) {
				font-size: 14px;
			}
		}
	}
	.bg-gray{
		background: #f8f8f8;
	}	
}

.hiddenText {
	width:1px;
	height:1px;
	display:block;
	overflow:hidden;
	text-indent:-9999px;
}

#modal_psp {
	> a,
	.modal-dialog > a,
	.modal-content > a {
		display: none;
	}
	.modal-body {
		p {
			color: $color-black;
			font-size: 16px;
		}
	}
}
