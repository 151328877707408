.email-result {
	margin:auto;
	max-width:(1284+30px);
	padding:44px 15px 50px;
	color:$color-nightrider;
	box-sizing:border-box; 	
	word-break: keep-all;
	@include screen(custom, max, $max-sm) {
		padding:44px 9px 30px;
	}
	
	/* [LGEUS-349] 20200413 add */
	.email-ceo-msg {
		margin-top: -40px;
		.title-ceo {
			padding: 13px 0 34px;
			margin-bottom: 44px;
			border-bottom: 2px solid #333;
			font-size: 48px;
			font-family: $font-semibold;
			text-align: center;
		}
		@include screen(custom, max, $max-sm) {
			margin-top: -40px;
			margin-bottom: 20px;
			padding: 0;
			.title-ceo {
				padding: 20px 0 30px;
				font-size: 32px;
				line-height: 36px;
			}
		}
	}
	/* // [LGEUS-349] 20200413 add */
	.inner {
		max-width:1284px;
		margin:0 auto;

		.completion-box { 
			.icon {
				display:inline-block;
				img{
					width:56px;height:56px;
				}
			}
			.completion-title {
				display:block;
				font-size:32px;
				margin:20px 0 2px;
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {	
					font-size:24px;	
					line-height:32px;
					margin-top:26px;
				}
			}
			.btn-box {
				margin-top:27px;
				@include screen(custom, max, $max-sm) {
					margin-top:32px;
				}
			}
			
			text-align:center;
			padding:53px 0 59px; 
			border:1px solid $line-gray;
			@include screen(custom, max, $max-sm) {	
				padding:53px 15px 49px; 
			}
		}	
		.title {
			font-size:24px;
			color:$color-black;
			@include font-family($font-semibold);
			margin-top:33px;
			display:block;
			line-height:28px;
			@include screen(custom, max, $max-sm) {
				font-size:20px;
				margin-top:23px;
			}
		}
		.table-wrap {
			width:100%;
			margin-top:10px;
			@include screen(custom, max, $max-sm) {
				margin-top:5px; 
			}
			colgroup {
				@include screen(custom, min, $min-md) {
					.cell-order01,
					.cell-order03 {
						width:17%;
					}
					.cell-order02,
					.cell-order04 {
						width:33%;
					}
				}
				@include screen(custom, max, $max-sm) {
					.cell-order01 {width:41%;}
					.cell-order02 {width:59%;}
				}
			}
		}
		.btn-area {
			text-align:center;
			margin:30px 0 0;
			.btn {
				margin:0 3px;
				@include screen(custom, max, $max-sm) {
					margin:0;
					display:block;
				}
			}
		}
		.question-box{
			margin-top:10px;
			border:1px solid $line-gray;
			@include screen(custom, max, $max-sm) {
				margin-top:5px;
			}
			.head{
				@extend %clearfix;
				position:relative;
				min-height:56px;
				padding:19px 200px 19px 28px;
				@include font-family($font-semibold);
				font-size:16px;
				line-height:20px;
				color:$color-black;
				border-bottom:1px solid $line-lightgray;
				background:$bg-whitesmoke;
				@include screen(custom, max, $max-sm) {
					padding:15px 19px 15px 19px;
				}
				.num{
					float:left;
					padding-right:10px;
					color:$color-carmine;
					font-size:16px;
					vertical-align:top;
					@include screen(custom, max, $max-sm) {
						float:none;
					}
				}
				.title {
					//float:left;
					//width:calc(100%-220px);
					margin:0;
					padding:0;
					font-size:16px;
					line-height:20px;
					vertical-align:top;
					@include screen(custom, max, $max-sm) {
						float:none;
						width:100%;
						margin-top:1px;
						line-height:24px;
					}
				}
				.date-info {
					display:block;
					position:absolute;
					right:29px;
					top:19px;
					color:$color-dimgray;
					@include screen(custom, max, $max-sm) {
						position:static;
						right:auto;
						top:auto;
						font-size:14px;
						margin-top:7px;
					}
				}
			}
			.text{
				padding:18px 28px;
				font-size:16px;
				line-height:24px;
				color:$color-dimgray;
				word-break:break-all;
				@include screen(custom, max, $max-sm) {
					padding:14px 19px;
					font-size:14px;
					line-height:20px;
				}
			}
		}
	}
	



	
		
		
	
	
}